.reset-button-div {
  position: absolute;
  top: 15px;
  left: 15px;
}

.reset-button {
  padding: 10px;
  height: 50px;
  width: 150px;
  border: none;
  border-radius: 15px;
  font-size: 1.4em;
  cursor: pointer;
  background: #c0c0c0;
}
.reset-button:hover {
  background: #cbcbcb;
}
.reset-button:active {ckground: #ababab;
  background: #ababab;
}
