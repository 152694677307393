.visualization-root {
  height: 100%;
  width: 100%;
  position: relative;
}

.viz-svg {
  height: 100%;
  width: 100%;
}

.view-limit-div {
  position: absolute;
  top: calc(15px + 50px + 20px);
  left: 15px;
}

.min-score-slider-div {
  position: absolute;
  top: calc(15px + 50px + 20px + 60px);
  left: 15px;
}
