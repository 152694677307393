.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  border-bottom: 2px solid black;
}

.navbar-logo {
  display: flex;
  justify-content: flex-start;
  width: 30%;
}

.navbar-title {
  display: flex;
  justify-content: center;
  width: 30%;
  font-size: 1.8em;
  font-weight: bold;
}

.navbar-search {
  display: flex;
  justify-content: center;
  width: 30%;
}

.navbar-search-input {
  padding: 7px;
  width: 90%;
}

:-ms-input-placeholder {  
  font-style: italic;
}

::-webkit-input-placeholder {
  font-style: italic;
}
